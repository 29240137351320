<template>
  <a-tag :color="planColor">{{ plan }}</a-tag>
</template>
<script setup lang="ts">
const props = defineProps({
  plan: {
    type: String,
    default: 'TRIAL'
  }
});

const planColor = computed(() => {
  if (props.plan === 'BASIC') {
    return 'grey';
  }
  if (props.plan === 'PREMIUM') {
    return '#108ee9';
  }

  return null;
});
</script>